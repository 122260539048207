<template>
	<!-- test for pushing new version to dev server -->
	<div>
		<div class="apex" v-if="$screen.width <= 992">
			<div class="carbon-footprint">
				<div class="header d-flex pt-3 pb-2">
					<div v-if="level == 'user'" class="project-title w-100">{{ FormMSG(14, 'My Carbon Footprint') }}</div>
					<div v-else class="project-title w-100">{{ FormMSG(14000, 'Project Carbon Footprint') }}</div>
				</div>
				<div class="block-chart">
					<div class="chart-resume">
						<div class="carbon-resume">
							<div class="carbon-quantity w-100">{{ formattedCarbonData ? formattedCarbonData.totalGenerated : '' }} - CO<sub>2</sub></div>
						</div>
						<VueApexCharts class="pt-2" width="410" type="donut" :options="chartMobileOptions" :series="series" />
					</div>
				</div>
			</div>
			<div class="thegreenshot-savings">
				<div class="header d-flex pt-3 pb-2">
					<div class="project-title w-100">{{ FormMSG(15, 'TheGreenShot Savings') }}</div>
				</div>
				<div class="thegreenshot-badge">
					<div class="paper-badge">
						<div class="resume">
							<div class="icon">
								<component :is="getLucideIcon('Files')" :size="22" />
							</div>
							<div class="label">{{ formattedCarbonData.tGSEcoPaper }} {{ FormMSG(16, 'pages') }}</div>
						</div>
						<div class="title">{{ FormMSG(17, 'Paper') }}</div>
					</div>
					<div class="separator" />
					<div class="resume-co2">
						<div class="resume">
							<div class="icon">
								<component :is="getLucideIcon('Sprout')" :size="22" />
							</div>
							<div class="label">{{ formattedCarbonData.tGSEconomy }}</div>
						</div>
						<div class="title">CO<sub>2</sub></div>
					</div>
				</div>
			</div>
		</div>
		<div class="d-flex flex-column justify-content-start" v-else>
			<b-row>
				<b-col v-if="level == 'user'" lg="8" md="6">
					<h2>{{ this.FormMSG(1, 'Your green impact') }} (CO<sub>2</sub>): {{ formattedCarbonData ? formattedCarbonData.totalGenerated : '' }}</h2>
				</b-col>
				<b-col v-if="level != 'user'" lg="8" md="6">
					<h2>
						{{ this.FormMSG(13, 'Project green impact') }} (CO<sub>2</sub>): {{ formattedCarbonData ? formattedCarbonData.totalGenerated : '' }}
					</h2>
				</b-col>
				<b-col lg="4" md="6">
					<h2>{{ this.FormMSG(8, 'The Green Shot savings') }}</h2>
				</b-col>
			</b-row>
			<b-row>
				<b-col v-if="formattedCarbonData" lg="8" md="6">
					<b-card class="card-border-blue-light">
						<b-row>
							<b-col cols="12" md="6">
								<b-card class="action-card">
									<b-card-body class="action-card-body clearfix">
										<div class="d-flex justify-content-start align-items-center">
											<i class="fa fa-bus" style="background-color: #6fdbd3; color: #ffffff"></i>
											<div class="d-flex flex-column">
												<div class="font-weight-bold">{{ this.FormMSG(2, 'Transport') }}</div>
												<div>CO<sub>2</sub>: {{ formattedCarbonData.transport }}</div>
											</div>
										</div>
									</b-card-body>
								</b-card>
							</b-col>
							<b-col cols="12" md="6">
								<b-card class="action-card">
									<b-card-body class="action-card-body clearfix">
										<div class="d-flex justify-content-start align-items-center">
											<i class="fa fa-hotel" style="background-color: #00a09c; color: #ffffff"></i>
											<div class="d-flex flex-column">
												<div class="font-weight-bold">{{ this.FormMSG(3, 'Accomodation') }}</div>
												<div>CO<sub>2</sub>: {{ formattedCarbonData.accomodation }}</div>
											</div>
										</div>
									</b-card-body>
								</b-card>
							</b-col>
							<b-col cols="12" md="6">
								<b-card class="action-card">
									<b-card-body class="action-card-body clearfix">
										<div class="d-flex justify-content-start align-items-center">
											<i class="fa fa-lightbulb-o" style="background-color: #225cbd; color: #ffffff"></i>
											<div class="d-flex flex-column">
												<div class="font-weight-bold">{{ this.FormMSG(4, 'Energy') }}</div>
												<div>CO<sub>2</sub>: {{ formattedCarbonData.energy }}</div>
											</div>
										</div>
									</b-card-body>
								</b-card>
							</b-col>
							<b-col cols="12" md="6">
								<b-card class="action-card">
									<b-card-body class="action-card-body clearfix">
										<div class="d-flex justify-content-start align-items-center">
											<i class="fa fa-credit-card" style="background-color: #658fd6; color: #ffffff"></i>
											<div class="d-flex flex-column">
												<div class="font-weight-bold">{{ this.FormMSG(6, 'Expenses') }}</div>
												<div>CO<sub>2</sub>: {{ formattedCarbonData.expenses }}</div>
											</div>
										</div>
									</b-card-body>
								</b-card>
							</b-col>
							<b-col v-if="level !== 'user'" cols="12" md="6">
								<b-card class="action-card">
									<b-card-body class="action-card-body clearfix">
										<div class="d-flex justify-content-start align-items-center">
											<i class="fa fa-building-o" style="background-color: #ea4d2c; color: #ffffff"></i>
											<div class="d-flex flex-column">
												<div class="font-weight-bold">{{ this.FormMSG(5, 'Locations') }} {{ this.level }}</div>
												<div>CO<sub>2</sub>: {{ formattedCarbonData.locations }}</div>
											</div>
										</div>
									</b-card-body>
								</b-card>
							</b-col>
							<b-col v-if="level !== 'user'" cols="12" md="6">
								<b-card class="action-card">
									<b-card-body class="action-card-body clearfix">
										<div class="d-flex justify-content-start align-items-center">
											<i class="fa fa-trash-o" style="background-color: #9b1b00; color: #ffffff"></i>
											<div class="d-flex flex-column">
												<div class="font-weight-bold">{{ this.FormMSG(7, 'Waste') }} {{ this.level }}</div>
												<div>CO<sub>2</sub>: {{ formattedCarbonData.waste }}</div>
											</div>
										</div>
									</b-card-body>
								</b-card>
							</b-col>
						</b-row>
						<b-row>
							<b-col :cols="$screen.width > 990 ? 6 : 12">
								<div id="chart">
									<VueApexCharts width="410" type="donut" :options="chartOptions" :series="series" />
								</div>
							</b-col>
							<b-col v-if="carbonModel === 2" :cols="$screen.width > 990 ? 6 : 12" :style="$screen.width > 990 ? '' : 'margin-top: 100px'">
								<div style="position: absolute; bottom: 10px; right: 30px">
									<img src="./../../assets/img/logo_doconomy.svg" alt="Logo doconomy" />
									<p style="font-weight: bolder">
										{{ FormMSG(157, 'Provided by Doconomy') }}
									</p>
								</div>
							</b-col>
						</b-row>
					</b-card>
				</b-col>
				<b-col v-if="formattedCarbonData" lg="4" md="6">
					<b-card class="card-border-blue-light">
						<div class="thegreenshot-badge-home">
							<div class="paper-badge">
								<div class="resume">
									<div class="icon">
										<component :is="getLucideIcon('Files')" :size="22" />
									</div>
									<div class="label">{{ formattedCarbonData.tGSEcoPaper }} {{ FormMSG(16, 'pages') }}</div>
								</div>
								<div class="title">{{ FormMSG(17, 'Paper') }}</div>
							</div>
							<div class="separator" />
							<div class="resume-co2">
								<div class="resume">
									<div class="icon"><component :is="getLucideIcon('Sprout')" :size="22" /></div>
									<div class="label">{{ formattedCarbonData.tGSEconomy }}</div>
								</div>
								<div class="title">CO<sub>2</sub></div>
							</div>
						</div>
					</b-card>
				</b-col>
			</b-row>
		</div>
	</div>
</template>

<script>
import numeral from 'numeral';
import languageMessages from '@/mixins/languageMessages';
import { store } from '@/store';
import VueApexCharts from 'vue-apexcharts';
import { isNil } from '~utils';
import globalMixin from '@/mixins/global.mixin';
import { getUserGreenDashboard, getAdminGreenDashboard } from '@/cruds/users.crud';

export default {
	name: 'CarbonDashboard',

	components: {
		VueApexCharts
	},
	mixins: [languageMessages, globalMixin],
	props: {
		level: {
			type: String
		}
	},
	data: function () {
		return {
			carbon: {},
			carbonModel: 0,
			series: []
		};
	},
	async created() {
		this.carbonModel = store.getCurrentProjectConfig().carbonModel;
		this.$bus.$on('bus:projectevent', (payload) => {
			this.carbonModel = payload.carbonModel;
		});
		try {
			await this.reloadData();
		} catch (error) {
			// do nothing;
		}
	},
	beforeDestroy() {
		// Don't forget to remove the event listener to avoid memory leaks
		this.$bus.$off('bus:projectevent');
	},
	methods: {
		async reloadData() {
			const carbonData = this.level === 'user' ? await getUserGreenDashboard() : await getAdminGreenDashboard();
			if (carbonData) {
				let coTwoData = [];
				this.carbon = carbonData;
				this.series = [];
				if (!isNil(this.carbon.carbonData)) {
					coTwoData.push(numeral(this.carbon.carbonData.transport).value());
					coTwoData.push(numeral(this.carbon.carbonData.accomodation).value());
					coTwoData.push(numeral(this.carbon.carbonData.energy).value());
					coTwoData.push(numeral(this.carbon.carbonData.expenses).value());
					if (this.level !== 'user') {
						coTwoData.push(numeral(this.carbon.carbonData.locations).value());
						coTwoData.push(numeral(this.carbon.carbonData.waste).value());
					}
				}
				this.series = coTwoData;
			}
		}
	},
	computed: {
		chartMobileOptions() {
			return {
				colors: ['#6FDBD3', '#00A09C', '#225CBD', '#658FD6', '#EA4D2C', '#9B1B00'],
				chart: {
					width: 380,
					type: 'donut'
				},
				plotOptions: {
					pie: {
						startAngle: -90,
						endAngle: 270
					}
				},
				dataLabels: {
					enabled: false
				},
				legend: {
					formatter: function (val, opts) {
						return val;
					},
					fontSize: '12px',
					fontWeight: 500,
					fontFamily: 'Archivo, Arial',
					offsetX: 8,
					offsetY: -2,
					markers: {
						width: 13,
						height: 13,
						radius: 2,
						// offsetX: -1,
						offsetY: 2
					}
				},
				labels: this.getLabels
			};
		},
		chartOptions() {
			return {
				colors: ['#6FDBD3', '#00A09C', '#225CBD', '#658FD6', '#EA4D2C', '#9B1B00'],
				chart: {
					width: 380,
					type: 'donut'
				},
				plotOptions: {
					pie: {
						startAngle: -90,
						endAngle: 270
					}
				},
				dataLabels: {
					enabled: false
				},
				legend: {
					formatter: function (val, opts) {
						return val;
					},
					fontSize: '12px',
					fontWeight: 500,
					offsetX: 8,
					offsetY: 40,
					markers: {
						width: 13,
						height: 13,
						radius: 2,
						// offsetX: -1,
						offsetY: 2
					}
				},
				labels: this.getLabels,
				title: {
					text: this.FormMSG(20, 'Your Green Impact distribution'),
					align: 'left',
					margin: 10,
					offsetX: 0,
					offsetY: 0,
					floating: false,
					style: {
						fontSize: '18px',
						fontWeight: 'bold',
						color: '#263238'
					}
				}
			};
		},
		formattedCarbonData() {
			//console.log('informattedcarbondata',this.carbon);
			if (Object.keys(this.carbon).length === 0) {
				return {};
			}
			return {
				transport:
					this.carbon.carbonData.transport > 1000
						? `${numeral(this.carbon.carbonData.transport / 1000).format('0,0.00')} T`
						: `${numeral(this.carbon.carbonData.transport).format('0,0.00')} Kg`,
				accomodation:
					this.carbon.carbonData.accomodation > 1000
						? `${numeral(this.carbon.carbonData.accomodation / 1000).format('0,0.00')} T`
						: `${numeral(this.carbon.carbonData.accomodation).format('0,0.00')} Kg`,
				energy:
					this.carbon.carbonData.energy > 1000
						? `${numeral(this.carbon.carbonData.energy / 1000).format('0,0.00')} T`
						: `${numeral(this.carbon.carbonData.energy).format('0,0.00')} Kg`,
				locations:
					this.carbon.carbonData.locations > 1000
						? `${numeral(this.carbon.carbonData.locations / 1000).format('0,0.00')} T`
						: `${numeral(this.carbon.carbonData.locations).format('0,0.00')} Kg`,
				expenses:
					this.carbon.carbonData.expenses > 1000
						? `${numeral(this.carbon.carbonData.expenses / 1000).format('0,0.00')} T`
						: `${numeral(this.carbon.carbonData.expenses).format('0,0.00')} Kg`,
				waste:
					this.carbon.carbonData.waste > 1000
						? `${numeral(this.carbon.carbonData.waste / 1000).format('0,0.00')} T`
						: `${numeral(this.carbon.carbonData.waste).format('0,0.00')} Kg`,
				tGSEconomy:
					this.carbon.carbonData.tGSEconomy > 1000
						? `${numeral(this.carbon.carbonData.tGSEconomy / 1000).format('0,0.00')} T`
						: `${numeral(this.carbon.carbonData.tGSEconomy).format('0,0.00')} Kg`,
				totalGenerated:
					this.carbon.carbonData.totalGenerated > 1000
						? `${numeral(this.carbon.carbonData.totalGenerated / 1000).format('0,0.00')} T`
						: `${numeral(this.carbon.carbonData.totalGenerated).format('0,0.00')} Kg`,
				tGSEcoPaper: `${numeral(this.carbon.carbonData.tGSEcoPaper).format('0,0')}` + ' '
			};
		},
		getLabels() {
			if (this.level === 'user') {
				return [
					this.FormMSG(2, 'Transport') + ': ' + this.formattedCarbonData.transport,
					this.FormMSG(3, 'Accomodation') + ': ' + this.formattedCarbonData.accomodation,
					this.FormMSG(4, 'Energy') + ': ' + this.formattedCarbonData.energy,
					this.FormMSG(6, 'Expenses') + ': ' + this.formattedCarbonData.expenses
				];
			} else {
				return [
					this.FormMSG(2, 'Transport') + ': ' + this.formattedCarbonData.transport,
					this.FormMSG(3, 'Accomodation') + ': ' + this.formattedCarbonData.accomodation,
					this.FormMSG(4, 'Energy') + ': ' + this.formattedCarbonData.energy,
					this.FormMSG(6, 'Expenses') + ': ' + this.formattedCarbonData.expenses,
					this.FormMSG(5, 'Location') + ': ' + this.formattedCarbonData.locations,
					this.FormMSG(7, 'Waste') + ': ' + this.formattedCarbonData.waste
				];
			}
		}
	}
	// watch: {
	// 	carbon: {
	// 		handler: function (n) {
	// 			if (!isNil(n)) {
	// 				this.carbon = n;
	// 				this.reloadData();
	// 			}
	// 		},
	// 		deep: true,
	// 		immediate: true
	// 	}
	// }
};
</script>

<style lang="scss" scoped>
.action-card {
	background-color: transparent;
	border: none;
	margin-bottom: 0;
}
.action-card-body {
	padding: 0;
}
.action-card-body i.fa {
	float: left;
	margin-right: 6px;
	width: 64px;
	height: 64px;
	background-color: #dcf8c0;
	border-radius: 32px;
	font-size: 27px;
	color: #007949;
	text-align: center;
	line-height: 64px;
}
.action-card-body p {
	padding: 6px 0;
}
.action-card-body p > * {
	display: block;
}
.result-card {
	background: linear-gradient(270deg, #00a09c 0%, #225cbd 100%);
	border-radius: 8px;
	color: white;
	text-align: center;
	border: none;
}
.result-card strong {
	font-size: 32px;
}
@media (max-width: 992px) {
	#chart {
		width: 100%;
	}
	#chart svg {
		width: 100%;
		height: auto;
	}
}

.thegreenshot-badge-home {
	display: inline-flex;
	box-sizing: border-box;
	width: 100%;
	.paper-badge,
	.resume-co2 {
		width: 50%;
		min-height: 75px;
		background: linear-gradient(270deg, #00a09c 0%, #225cbd 100%);
		border-radius: 8px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		color: #fff;
		padding: 16px;
		.resume {
			display: inline-flex;
			width: 100%;
			.icon {
				padding-right: 8px;
			}
			.label {
				font-weight: 600;
				font-size: 15px;
				letter-spacing: 0.01em;
			}
		}
		.title {
			padding-left: 30px;
			font-weight: 500;
			font-size: 13px;
			letter-spacing: 0.05em;
			width: 100%;
			color: #f5f7f9;
		}
	}
	.separator {
		width: 16px;
	}
}
</style>
<!-- background: linear-gradient(180deg, #65A930 0%, #007949 100%); -->
