<script>
import { Line } from 'vue-chartjs';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import moment from 'moment';
import randomColor from 'randomcolor';

let colors = {};

/**
 * transform minutes in hours
 * args min: Number
 * @return hours
 */
function transformMinutesToHours(min) {
	return min / 60;
}

function identity(y) {
	return y;
}

/**
 * reduce an array by key: returns an object with keys containing an array of elements of the input array
 * @argument array @array the array to be reduced
 * @argument mainKey @string the main key to be used as a dimension to reduce the array
 * @argument secondaryKeys @array of @strings of keys different from the mainKey to be retained on array element
 */
function reduceArrayByKey(array, mainKey, secondaryKeys) {
	return array.reduce((agg, elem) => {
		const toPush = secondaryKeys.reduce((agg, secondaryKey) => {
			agg[secondaryKey] = elem[secondaryKey];
			return agg;
		}, {});
		if (agg[elem[mainKey]]) {
			agg[elem[mainKey]].push(toPush);
		} else {
			agg[elem[mainKey]] = [toPush];
		}
		return agg;
	}, {});
}

/**
 * reduce and aggregate an array by a key
 * @argument array the array to be reduced
 * @argument dimensionKey the dimension on which the aggregartion must be performed
 * @argument valueKey the key that is summed accross the dimension
 */

function reduceSumArrayByKey(array, dimensionKey, valueKey) {
	const reduced = array.reduce((agg, elem) => {
		if (agg[elem[dimensionKey]]) {
			agg[elem[dimensionKey]] += elem[valueKey];
		} else {
			agg[elem[dimensionKey]] = elem[valueKey];
		}
		return agg;
	}, {});
	return Object.keys(reduced).map((x) => ({
		[dimensionKey]: x,
		[valueKey]: reduced[x]
	}));
}

const graphDataKeyDictionnary = {
	timesheets: {
		accessor: 'tsDays',
		x: 'date',
		y: 'totMin',
		transformY: transformMinutesToHours
	},
	expenses: {
		accessor: 'expenseItems',
		x: 'date',
		y: 'amountTotal',
		transformY: identity
	},
	pos: {
		accessor: 'poItems',
		x: 'requestDate',
		y: 'total',
		transformY: identity
	}
};
/**
 * filter array by selected departments
 * @argument data array of data
 * @argument selectedDepartments array of strings of deppartment names
 */

function filterGraphDataByDepartment(data, selectedDepartments) {
	if (selectedDepartments.indexOf('ALL') > -1) {
		return data;
	}
	return data.filter((dp) => selectedDepartments.indexOf(dp.user.departmentName) > -1);
}

/** filter out elements with invalid date at dateKey in array data
 * @argument data: array of elements
 * @argument dateKey: the key containing the date ot filter on
 */

function filterInvalidDate(data, dateKey) {
	return data.filter((d) => {
		const momentDate = new moment(d[dateKey]);
		if (!momentDate.isValid()) {
			return false;
		}
		if (momentDate.isSameOrBefore(new moment('1970-01-01'))) {
			return false;
		}
		return true;
	});
}

function filterGraphDataByRange(data, dateRange, dateKey) {
	const { startDate, endDate } = dateRange;
	const minDate = startDate ? new moment(startDate) : null;
	const maxDate = endDate ? new moment(endDate) : null;
	return filterInvalidDate(data, dateKey).filter((d) => {
		const momentDate = new moment(d[dateKey]);
		if (minDate && maxDate) {
			return momentDate.isSameOrAfter(minDate) && momentDate.isSameOrBefore(maxDate);
		}
		if (minDate) {
			return momentDate.isSameOrAfter(minDate);
		}
		if (maxDate) {
			return momentDate.isSameOrBefore(maxDate);
		}
		return true;
	});
}

function filterData(data, selectedDepartments, dateRange, dateKey) {
	return filterGraphDataByRange(filterGraphDataByDepartment(data, selectedDepartments), dateRange, dateKey);
}

function getAccessorFromView(view) {
	const viewLC = view.toLowerCase();
	if (viewLC === 'timesheets') {
		return 'timesheets';
	}
	if (viewLC === 'expenses') {
		return 'expenses';
	}
	return 'pos';
}

function buildDeepDivedGraphData(data, dateRange, view, deepDivedDepartment) {
	const key = getAccessorFromView(view);
	const keyDict = graphDataKeyDictionnary[key];
	const { accessor, x, y, transformY } = keyDict;
	const filteredData = filterData(data[accessor], [deepDivedDepartment], dateRange, x);
	if (view.toLowerCase() === 'timesheets') {
		const dataByUser = filteredData.map((d) => ({
			y: d[keyDict.y],
			x: d[keyDict.x],
			user: `${d.user.firstName} ${d.user.name}`
		}));
		const dataReducedByUser = reduceArrayByKey(dataByUser, 'user', ['x', 'y']);
		const summedTimeSheets = Object.keys(dataReducedByUser).reduce((agg, user) => {
			agg[user] = reduceSumArrayByKey(dataReducedByUser[user], 'x', 'y');
			return agg;
		}, {});
		const ret = {
			datasets: Object.keys(summedTimeSheets).map((user) => {
				const userData = summedTimeSheets[user]
					.map((d, i) => ({
						x: new Date(d.x),
						y: transformY(d.y)
					}))
					.sort((a, b) => a.x - b.x);
				if (!colors[user]) {
					colors[user] = randomColor();
				}
				return {
					label: user,
					backgroundColor: 'rgba(0,0,0,0)',
					borderColor: colors[user],
					pointHoverBackgroundColor: '#fff',
					borderWidth: 2,
					data: userData
				};
			})
		};
		return ret;
	} else {
		const dataByCategory = filteredData.map((d) => ({
			y: d[keyDict.y],
			x: d[keyDict.x],
			category: d.categoryName
		}));
		const dataReducedByCategory = reduceArrayByKey(dataByCategory, 'category', ['x', 'y']);
		const summedData = Object.keys(dataReducedByCategory).reduce((agg, category) => {
			agg[category] = reduceSumArrayByKey(dataReducedByCategory[category], 'x', 'y');
			return agg;
		}, {});
		return {
			datasets: Object.keys(summedData).map((category) => {
				const categoryData = summedData[category]
					.map((d, i) => ({
						x: new Date(d.x),
						y: transformY(d.y)
					}))
					.sort((a, b) => a.x - b.x);
				if (!colors[category]) {
					colors[category] = randomColor();
				}
				return {
					label: category,
					backgroundColor: 'rgba(0,0,0,0)',
					borderColor: colors[category],
					pointHoverBackgroundColor: '#fff',
					borderWidth: 2,
					data: categoryData
				};
			})
		};
	}
}

function buildGraphData(data, selectedDepartments, dateRange, view, deepDivedDepartment) {
	const key = getAccessorFromView(view);
	const keyDict = graphDataKeyDictionnary[key];
	const { accessor, x, y, transformY } = keyDict;
	if (!data || data.length === 0) {
		return [];
	}
	if (deepDivedDepartment !== null) {
		return buildDeepDivedGraphData(data, dateRange, view, deepDivedDepartment);
	}
	const filteredData = filterData(data[accessor], selectedDepartments, dateRange, x);
	const dataByDepartments = filteredData.map((d) => ({
		y: d[keyDict.y],
		department: d.user.departmentName,
		x: new moment(d[keyDict.x]).startOf('day')
	}));
	const dataReducedByDepartment = reduceArrayByKey(dataByDepartments, 'department', ['x', 'y']);
	const summedTimeSheets = Object.keys(dataReducedByDepartment).reduce((agg, department) => {
		agg[department] = reduceSumArrayByKey(dataReducedByDepartment[department], 'x', 'y');
		return agg;
	}, {});
	const ret = {
		datasets: Object.keys(summedTimeSheets).map((department) => {
			const departmentData = summedTimeSheets[department]
				.map((d, i) => ({
					x: new Date(d.x),
					y: transformY(d.y)
				}))
				.sort((a, b) => a.x - b.x);
			if (!colors[department]) {
				colors[department] = randomColor();
			}
			// console.log("departmentData");
			// console.log(departmentData.map(({ x, y }) => ({ x, y })));
			return {
				label: department,
				backgroundColor: 'rgba(0,0,0,0)', //hexToRgba(brandInfo, 10),
				borderColor: colors[department],
				pointHoverBackgroundColor: '#fff',
				borderWidth: 2,
				data: departmentData
			};
		})
	};
	return ret;
}

export default {
	extends: Line,
	props: ['height', 'selectedDepartments', 'graphData', 'selectedView', 'deepDivedDepartment', 'disableDeepDiving', 'dateRange'],
	data() {
		return {
			chartdata: null,
			options: this.buildOptions(this.chartdata)
		};
	},
	watch: {
		selectedDepartments() {
			this.updateChart();
		},
		dateRange() {
			this.updateChart();
		},
		graphData() {
			this.updateChart();
		},
		selectedView(view, prevView) {
			if (view !== prevView) {
				this.updateChart();
			}
		},
		deepDivedDepartment() {
			this.updateChart();
		}
	},
	mounted() {
		this.renderChart(this.chartdata, this.options);
	},
	methods: {
		selectDepartmentByName(depName) {
			if (!this.disableDeepDiving || !depName) {
				this.$emit('setDeepDivedDepartment', depName);
			}
		},
		buildOptions(chartdata) {
			//console.log("this.buildOptions");
			//console.log(this.buildOptions);
			const that = this;
			return {
				onClick: function (args, args2) {
					if (args2.length > 0 && !!chartdata) {
						const datasetIndex = this.getElementAtEvent(args)[0]._datasetIndex;
						const datasets = chartdata.datasets;
						const dataset = datasets[datasetIndex];
						that.selectDepartmentByName(dataset.label);
					} else {
						that.selectDepartmentByName(null);
					}
				},
				tooltips: {
					enabled: false,
					custom: CustomTooltips,
					intersect: true,
					mode: 'single',
					position: 'nearest',
					callbacks: {
						labelColor: function (tooltipItem, chart) {
							return {
								backgroundColor: chart.data.datasets[tooltipItem.datasetIndex].borderColor
							};
						},
						label: function (tooltipItem, datasets) {
							const { yLabel, datasetIndex } = tooltipItem;
							const { label } = datasets.datasets[datasetIndex];
							return `${label}: ${Math.round(yLabel * 100) / 100}`;
						},
						title: function (tooltipItem) {
							return new moment(tooltipItem[0].xLabel).format('MMMM DD YYYY');
						}
					}
				},
				maintainAspectRatio: false,
				legend: {
					display: true,
					onClick: (_, clickedLabel) => {
						if (clickedLabel && !that.deepDivedDepartment) {
							const datasetIndex = clickedLabel.datasetIndex;
							const dataset = chartdata.datasets[datasetIndex];
							that.selectDepartmentByName(dataset.label);
						} else {
							that.selectDepartmentByName(null);
						}
					}
				},
				scales: {
					xAxes: [
						{
							type: 'time',
							time: {
								displayFormats: {
									day: 'MMM DD YYYY',
									hour: 'MMM DD YYYY'
								}
							},
							// gridLines: {
							//   drawOnChartArea: false
							// },
							ticks: {
								beginAtZero: false,
								maxTicksLimit: 12,
								padding: 12
							},
							gridLines: {
								display: false
							}
						}
					],
					yAxes: [
						{
							ticks: {
								beginAtZero: true
							},
							gridLines: {
								display: true
							}
						}
					]
				},
				elements: {
					point: {
						radius: 2,
						hitRadius: 2,
						hoverRadius: 4,
						hoverBorderWidth: 3
					}
				}
			};
		},
		updateChart() {
			this.chartdata = buildGraphData(this.graphData, this.selectedDepartments, this.dateRange, this.selectedView, this.deepDivedDepartment);
			this.options = this.buildOptions(this.chartdata);
			this.renderChart(this.chartdata, this.options);
		}
	}
};
</script>
