var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "12", md: "4" } },
            [
              _vm.numTsToValidate > 0
                ? _c(
                    "b-button",
                    {
                      attrs: { block: "", variant: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.navigateToPath("/validateTS")
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.numTsToValidate) +
                          "\n\t\t\t\t"
                      ),
                      _c("span", [
                        _vm._v(
                          _vm._s(this.FormMSG(10, "timesheets to validate"))
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12", md: "4" } },
            [
              _vm.numExpToValidate > 0
                ? _c(
                    "b-button",
                    {
                      attrs: { block: "", variant: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.navigateToPath("/validateExp")
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.numExpToValidate) +
                          "\n\t\t\t\t"
                      ),
                      _c("span", [
                        _vm._v(
                          _vm._s(this.FormMSG(11, "expenses to validate"))
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12", md: "4" } },
            [
              _vm.numPoToValidate > 0
                ? _c(
                    "b-button",
                    {
                      attrs: { block: "", variant: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.navigateToPath("/validatePO")
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.numPoToValidate) +
                          "\n\t\t\t\t"
                      ),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            this.FormMSG(12, "purchase orders to validate")
                          )
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }