<template>
	<b-container fluid class="hidden-sm">
		<evolution-dashboard-header
			v-if="displayHeader"
			:num-ts-to-validate="numTsToValidate"
			:num-exp-to-validate="numExpToValidate"
			:num-po-to-validate="numPoToValidate"
		/>
		<h2 class="mt-2">{{ this.caption1 }}</h2>
		<b-card no-body>
			<b-card-header>
				<b-container fluid>
					<b-row>
						<b-col class="buttons-header">
							<b-button variant="light" :class="'Timesheets' === state.selectedView ? 'btn-current' : ''" @click="selectView('Timesheets')">
								{{ this.FormMSG(20, 'Timesheets') }}
							</b-button>
							<b-button variant="light" :class="'Expenses' === state.selectedView ? 'btn-current' : ''" @click="selectView('Expenses')">
								{{ this.FormMSG(21, 'Expenses') }}
							</b-button>
							<b-button
								v-if="!isFilmSingle"
								variant="light"
								:class="'Purchase orders' === state.selectedView ? 'btn-current' : ''"
								@click="selectView('Purchase orders')"
							>
								{{ this.FormMSG(22, 'Purchase orders') }}
							</b-button>
							<!--
                :locale-data="{ firstDay: 1, format: 'YYYY-MM-DD' }"
                            :local-data="getLocalDataForDatePicker"
                                              :ranges="state.quickDateRanges"
                                              'This month': thisMonth,
                                              'This year': thisYear,
                                              All: []
             -->
							<div class="dashboard-timeframe-selector-container">
								<date-range-picker
									ref="picker"
									v-model="state.dateRange"
									:opens="'left'"
									:show-week-numbers="true"
									:show-dropdowns="true"
									:auto-apply="true"
									:time-picker="false"
									:ranges="getLanguage() == 1 ? state.quickDateRangesFr : state.quickDateRangesEn"
									:linked-calendars="false"
									:locale-data="
										getLanguage() == 1
											? {
													firstDay: 1,
													format: 'YYYY-MM-DD',
													daysOfWeek: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
													monthNames: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sep', 'Oct', 'Nov', 'Dec'],
													weekLabel: 'S'
											  }
											: {
													firstDay: 1,
													format: 'YYYY-MM-DD',
													daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
													monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
											  }
									"
									@update="updateValues"
								>
									<template #input="picker" style="min-width: 350px">
										{{ displayedDate }}
									</template>
								</date-range-picker>
							</div>
						</b-col>
					</b-row>
				</b-container>
			</b-card-header>
			<b-card-body>
				<evolution-chart
					:selected-departments="state.selectedDepartments"
					:graph-data="adminDashboard"
					:date-range="state.dateRange"
					:selected-view="state.selectedView"
					:disable-deep-diving="level === 'user' || !!state.deepDivedDepartment"
					:deep-dived-department="state.deepDivedDepartment"
					:height="480"
					@setDeepDivedDepartment="setDeepDivedDepartment"
				/>
				<evolution-dashboard-department-selector
					v-if="level !== 'user'"
					:departments="departments"
					:selected-departments="state.selectedDepartments"
					@changeSelectedDepartments="onSelectDepartmentsFromSelector"
				/>
				<div>
					<!--  {{ level }} -->
				</div>
			</b-card-body>
		</b-card>
	</b-container>
</template>

<script>
import gql from 'graphql-tag';
import moment from 'moment';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import './DateRangePicker.css';
import languageMessages from '@/mixins/languageMessages';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';

import EvolutionDashboardDepartmentSelector from './EvolutionDashboardDepartmentsSelector';
import EvolutionChart from './EvolutionChart';
import EvolutionDashboardHeader from './EvolutionDashboardHeader';

import { rendCurrency } from '~helpers';
import { isNil } from '@/shared/utils';
import { store } from '@/store';
import { getUserDashBoardEvolution, getAdminDashBoardEvolution } from '@/cruds/users.crud';

var SELECTABLE_VIEWS = ['Timesheets', 'Expenses', "PO's"];
const now = new moment();
const startOfWeek = new moment().startOf('week');
const startOfMonth = new moment().startOf('month');
const startOfYear = new moment().startOf('year');
const startOfTime = new moment('1970-01-01', 'YYYY-MM-DD');
const nowDate = new Date(now);

const thisWeek = [new Date(startOfWeek), nowDate];
const thisMonth = [new Date(startOfMonth), nowDate];
const thisYear = [new Date(startOfYear), nowDate];

const quickDateRangesEn = {
	'This week': thisWeek,
	'This month': thisMonth,
	'This year': thisYear,
	All: []
};
const quickDateRangesFr = {
	'Cette semaine': thisWeek,
	'Ce mois': thisMonth,
	'Cette année': thisYear,
	'toutes les dates': []
};

function getDepartmentsFromData(data) {
	return Array.from(new Set(data.map((dp) => dp.user.departmentName)));
}

// const QUERY_NAME = 'GetAdminDashboard';
export default {
	name: 'EvolutionDashboard',
	mixins: [languageMessages, isSingleProjectMixin],
	components: {
		EvolutionDashboardDepartmentSelector,
		EvolutionChart,
		EvolutionDashboardHeader,
		DateRangePicker
	},
	props: {
		level: {
			type: String
		}
	},
	data: function () {
		const displayHeader = store.isProd() || store.isAdmin();
		//SELECTABLE_VIEWS[0] = this.FormMSG(20,"Timesheets");
		//SELECTABLE_VIEWS[1] = this.FormMSG(21,"Expenses");
		//SELECTABLE_VIEWS[2] = this.FormMSG(22,"Purchase orders");
		const d = {
			lang: 1,
			state: {
				availableViews: SELECTABLE_VIEWS,
				selectedView: SELECTABLE_VIEWS[0],
				selectedDepartments: [],
				deepDivedDepartment: null,
				dateRange: {},
				quickDateRangesEn,
				quickDateRangesFr
			},
			displayHeader,
			adminDashboard: [],
			departments: [], // getDepartmentsFromData(this.adminDashboard),
			numTsToValidate: null,
			numExpToValidate: null,
			numPoToValidate: null
		};
		return d;
	},
	computed: {
		caption1() {
			var str = this.FormMSG(10, 'Evolution over time') + ' ' + rendCurrency('()');
			//console.log("getCurrencyWithParenthesis:",str);
			return str;
		},
		displayedDate() {
			if (this.startDate && this.endDate) {
				if (this.startDate === startOfWeek.format('YYYY-MM-DD')) {
					return this.FormMSG(31, 'This week');
				}
				if (this.startDate === startOfMonth.format('YYYY-MM-DD')) {
					return this.FormMSG(32, 'This month');
				}
				if (this.startDate === startOfYear.format('YYYY-MM-DD')) {
					return this.FormMSG(33, 'This year');
				}
				if (this.startDate === startOfTime.format('YYYY-MM-DD')) {
					return this.FormMSG(34, 'All dates');
				}
				return `${this.startDate} -> ${this.endDate}`;
			}
			return this.FormMSG(34, 'All dates');
		},
		startDate() {
			if (this.state.dateRange && this.state.dateRange.startDate) {
				return new moment(this.state.dateRange.startDate).format('YYYY-MM-DD');
			}
			return '';
		},
		endDate() {
			if (this.state.dateRange && this.state.dateRange.endDate) {
				return new moment(this.state.dateRange.endDate).format('YYYY-MM-DD');
			}
			return '';
		}
	},
	methods: {
		getLanguage() {
			if (isNil(store) || isNil(store.appLanguage)) {
				return 0;
			}
			var lang = store.appLanguage();
			//console.log("language=" ,lang);
			return lang;
		},
		getLocalDataForDatePicker() {
			return {
				direction: 'ltr',
				format: 'YYYY-MM-DD',
				separator: ' - ',
				applyLabel: 'Apply',
				cancelLabel: 'Cancel',
				weekLabel: 'S',
				customRangeLabel: 'Custom Range',
				daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
				monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
				firstDay: 1
			};
		},
		dateFormat(__, date) {
			return new moment(date).format('YYYY-MM-DD');
		},
		updateValues(args) {
			const { startDate, endDate } = args;
			if (startDate && endDate && new moment(startDate).format('YYYY-MM-DD') !== startOfTime.format('YYYY-MM-DD')) {
				this.state.dateRange = { startDate, endDate };
			}
		},
		selectView(view) {
			this.state.selectedView = view;
		},
		setDeepDivedDepartment(depName) {
			this.state.deepDivedDepartment = depName;
			if (depName) {
				this.onChangeSelectedDepartments([depName]);
			}
		},
		onChangeSelectedDepartments(args) {
			this.state.selectedDepartments = args;
		},
		onSelectDepartmentsFromSelector(args) {
			this.onChangeSelectedDepartments(args);
			this.state.deepDivedDepartment = null;
		},
		setAvailableDepartments(departments) {
			this.departments = departments;
		},
		setMainKpis({ numTsToValidate, numPoToValidate, numExpToValidate }) {
			this.numTsToValidate = numTsToValidate;
			this.numPoToValidate = numPoToValidate;
			this.numExpToValidate = numExpToValidate;
		},
		async initEvolutionDashboard() {
			const data = this.level === 'user' ? await getUserDashBoardEvolution() : await getAdminDashBoardEvolution();
			this.adminDashboard = data;
			const allData = ['tsDays', 'expenseItems', 'poItems']
				.map((accessKey) => data[accessKey])
				.flat()
				.map((d) => d.user.departmentName);
			const availableDepartments = Array.from(new Set(allData));
			this.setAvailableDepartments(availableDepartments);
			this.onChangeSelectedDepartments(availableDepartments);
			const { numTsToValidate, numPoToValidate, numExpToValidate } = data;
			this.setMainKpis({
				numTsToValidate,
				numPoToValidate,
				numExpToValidate
			});
			if (this.level === 'user' && availableDepartments.length === 1) {
				this.setDeepDivedDepartment(availableDepartments[0]);
			}
		}
	},
	async created() {
		await this.initEvolutionDashboard();
	}
};
</script>

<style scoped>
.button {
	margin-left: auto;
	margin-right: auto;
}
.btn-light {
	font-weight: normal;
	margin-right: 8px;
}
.btn-light.btn-current {
	color: #007949;
	font-weight: bold;
}
.dashboard-timeframe-selector-container {
	position: absolute;
	top: 0;
	right: 0;
}
.center {
	display: flex;
	justify-content: center;
}

h4 {
	margin-bottom: 12px;
}
</style>
