var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("p"),
      _c(
        "b-form-group",
        {
          attrs: {
            label: this.FormMSG(1, "Display data for departments:"),
            "label-for": "checkbox-group-1",
          },
        },
        [
          _c("b-form-checkbox-group", {
            attrs: {
              id: "checkbox-group-1",
              options: _vm.options,
              name: "flavour-1",
            },
            on: { change: _vm.changeSelection },
            model: {
              value: _vm.selectedDepartments,
              callback: function ($$v) {
                _vm.selectedDepartments = $$v
              },
              expression: "selectedDepartments",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }