var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "px-0", attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        { staticClass: "w-100" },
        [
          _vm.level == "user"
            ? _c("b-col", { attrs: { cols: "12" } }, [
                _c("h3", { staticStyle: { margin: "0" } }, [
                  _vm._v(_vm._s(this.FormMSG(14, "Your Personal Overview"))),
                ]),
              ])
            : _vm._e(),
          _vm.level != "user"
            ? _c("b-col", { attrs: { cols: "12" } }, [
                _c(
                  "h3",
                  { staticStyle: { margin: "0", "margin-bottom": "12px" } },
                  [_vm._v(_vm._s(this.FormMSG(13, "Project overview")))]
                ),
              ])
            : _vm._e(),
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "b-card",
                { staticClass: "kpi-card" },
                [
                  _c(
                    "b-card-body",
                    { staticClass: "kpi-card-body" },
                    [
                      _c(
                        "b-container",
                        { attrs: { fluid: "" } },
                        [
                          _c(
                            "b-row",
                            [
                              _vm.level != "user"
                                ? _c(
                                    "b-col",
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            this.FormMSG(10, "Total expenses:")
                                          ) + " "
                                        ),
                                      ]),
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(_vm.formattedKPIS.expenses)
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.level == "user"
                                ? _c(
                                    "b-col",
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            this.FormMSG(15, "Expenses:")
                                          ) + " "
                                        ),
                                      ]),
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(_vm.formattedKPIS.expenses)
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.level != "user"
                                ? _c(
                                    "b-col",
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            this.FormMSG(
                                              11,
                                              "Total Purchase Orders:"
                                            )
                                          )
                                        ),
                                      ]),
                                      _c("strong", [
                                        _vm._v(_vm._s(_vm.formattedKPIS.pos)),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.level == "user"
                                ? _c(
                                    "b-col",
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            this.FormMSG(16, "Purchase Orders:")
                                          )
                                        ),
                                      ]),
                                      _c("strong", [
                                        _vm._v(_vm._s(_vm.formattedKPIS.pos)),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.level != "user"
                                ? _c(
                                    "b-col",
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            this.FormMSG(12, "Total Salaries:")
                                          )
                                        ),
                                      ]),
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(_vm.formattedKPIS.salaries)
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.level == "user"
                                ? _c(
                                    "b-col",
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(this.FormMSG(17, "Salary:"))
                                        ),
                                      ]),
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(_vm.formattedKPIS.salaries)
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }