<template>
	<div>
		<!--label="Departments:"-->
		<p></p>
		<b-form-group :label="this.FormMSG(1, 'Display data for departments:')" label-for="checkbox-group-1">
			<b-form-checkbox-group
				id="checkbox-group-1"
				v-model="selectedDepartments"
				:options="options"
				name="flavour-1"
				@change="changeSelection"
			></b-form-checkbox-group>
		</b-form-group>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';

export default {
	name: 'EvolutionDashboardDepartmentSelector',
	mixins: [languageMessages],

	props: {
		departments: Array,
		selectedDepartments: { type: Array, default: [] }
	},
	computed: {
		options() {
			const departments = this.departments;
			if (departments && departments.length && departments.length > 0) {
				const ret = departments.map((d) => ({
					text: d,
					value: d
				}));
				return ret;
				//return [].concat([{ text: "ALL", value: "ALL"}],ret)
			}
			return [];
		}
	},
	methods: {
		changeSelection(args) {
			if (args.indexOf('ALL') > -1) {
				const values = this.options.map((d) => d.value);
				this.$emit(
					'changeSelectedDepartments',
					this.options.map((d) => d.value)
				);
			} else {
				this.$emit('changeSelectedDepartments', args);
			}
		}
	}
};
</script>
