var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.$screen.width <= 992
      ? _c("div", { staticClass: "apex" }, [
          _c("div", { staticClass: "carbon-footprint" }, [
            _c("div", { staticClass: "header d-flex pt-3 pb-2" }, [
              _vm.level == "user"
                ? _c("div", { staticClass: "project-title w-100" }, [
                    _vm._v(_vm._s(_vm.FormMSG(14, "My Carbon Footprint"))),
                  ])
                : _c("div", { staticClass: "project-title w-100" }, [
                    _vm._v(
                      _vm._s(_vm.FormMSG(14000, "Project Carbon Footprint"))
                    ),
                  ]),
            ]),
            _c("div", { staticClass: "block-chart" }, [
              _c(
                "div",
                { staticClass: "chart-resume" },
                [
                  _c("div", { staticClass: "carbon-resume" }, [
                    _c("div", { staticClass: "carbon-quantity w-100" }, [
                      _vm._v(
                        _vm._s(
                          _vm.formattedCarbonData
                            ? _vm.formattedCarbonData.totalGenerated
                            : ""
                        ) + " - CO"
                      ),
                      _c("sub", [_vm._v("2")]),
                    ]),
                  ]),
                  _c("VueApexCharts", {
                    staticClass: "pt-2",
                    attrs: {
                      width: "410",
                      type: "donut",
                      options: _vm.chartMobileOptions,
                      series: _vm.series,
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "thegreenshot-savings" }, [
            _c("div", { staticClass: "header d-flex pt-3 pb-2" }, [
              _c("div", { staticClass: "project-title w-100" }, [
                _vm._v(_vm._s(_vm.FormMSG(15, "TheGreenShot Savings"))),
              ]),
            ]),
            _c("div", { staticClass: "thegreenshot-badge" }, [
              _c("div", { staticClass: "paper-badge" }, [
                _c("div", { staticClass: "resume" }, [
                  _c(
                    "div",
                    { staticClass: "icon" },
                    [
                      _c(_vm.getLucideIcon("Files"), {
                        tag: "component",
                        attrs: { size: 22 },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "label" }, [
                    _vm._v(
                      _vm._s(_vm.formattedCarbonData.tGSEcoPaper) +
                        " " +
                        _vm._s(_vm.FormMSG(16, "pages"))
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.FormMSG(17, "Paper"))),
                ]),
              ]),
              _c("div", { staticClass: "separator" }),
              _c("div", { staticClass: "resume-co2" }, [
                _c("div", { staticClass: "resume" }, [
                  _c(
                    "div",
                    { staticClass: "icon" },
                    [
                      _c(_vm.getLucideIcon("Sprout"), {
                        tag: "component",
                        attrs: { size: 22 },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "label" }, [
                    _vm._v(_vm._s(_vm.formattedCarbonData.tGSEconomy)),
                  ]),
                ]),
                _vm._m(0),
              ]),
            ]),
          ]),
        ])
      : _c(
          "div",
          { staticClass: "d-flex flex-column justify-content-start" },
          [
            _c(
              "b-row",
              [
                _vm.level == "user"
                  ? _c("b-col", { attrs: { lg: "8", md: "6" } }, [
                      _c("h2", [
                        _vm._v(
                          _vm._s(this.FormMSG(1, "Your green impact")) + " (CO"
                        ),
                        _c("sub", [_vm._v("2")]),
                        _vm._v(
                          "): " +
                            _vm._s(
                              _vm.formattedCarbonData
                                ? _vm.formattedCarbonData.totalGenerated
                                : ""
                            )
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.level != "user"
                  ? _c("b-col", { attrs: { lg: "8", md: "6" } }, [
                      _c("h2", [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(this.FormMSG(13, "Project green impact")) +
                            " (CO"
                        ),
                        _c("sub", [_vm._v("2")]),
                        _vm._v(
                          "): " +
                            _vm._s(
                              _vm.formattedCarbonData
                                ? _vm.formattedCarbonData.totalGenerated
                                : ""
                            ) +
                            "\n\t\t\t\t"
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _c("b-col", { attrs: { lg: "4", md: "6" } }, [
                  _c("h2", [
                    _vm._v(_vm._s(this.FormMSG(8, "The Green Shot savings"))),
                  ]),
                ]),
              ],
              1
            ),
            _c(
              "b-row",
              [
                _vm.formattedCarbonData
                  ? _c(
                      "b-col",
                      { attrs: { lg: "8", md: "6" } },
                      [
                        _c(
                          "b-card",
                          { staticClass: "card-border-blue-light" },
                          [
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  { attrs: { cols: "12", md: "6" } },
                                  [
                                    _c(
                                      "b-card",
                                      { staticClass: "action-card" },
                                      [
                                        _c(
                                          "b-card-body",
                                          {
                                            staticClass:
                                              "action-card-body clearfix",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex justify-content-start align-items-center",
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "fa fa-bus",
                                                  staticStyle: {
                                                    "background-color":
                                                      "#6fdbd3",
                                                    color: "#ffffff",
                                                  },
                                                }),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex flex-column",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "font-weight-bold",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              2,
                                                              "Transport"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c("div", [
                                                      _vm._v("CO"),
                                                      _c("sub", [_vm._v("2")]),
                                                      _vm._v(
                                                        ": " +
                                                          _vm._s(
                                                            _vm
                                                              .formattedCarbonData
                                                              .transport
                                                          )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { cols: "12", md: "6" } },
                                  [
                                    _c(
                                      "b-card",
                                      { staticClass: "action-card" },
                                      [
                                        _c(
                                          "b-card-body",
                                          {
                                            staticClass:
                                              "action-card-body clearfix",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex justify-content-start align-items-center",
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "fa fa-hotel",
                                                  staticStyle: {
                                                    "background-color":
                                                      "#00a09c",
                                                    color: "#ffffff",
                                                  },
                                                }),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex flex-column",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "font-weight-bold",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              3,
                                                              "Accomodation"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c("div", [
                                                      _vm._v("CO"),
                                                      _c("sub", [_vm._v("2")]),
                                                      _vm._v(
                                                        ": " +
                                                          _vm._s(
                                                            _vm
                                                              .formattedCarbonData
                                                              .accomodation
                                                          )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { cols: "12", md: "6" } },
                                  [
                                    _c(
                                      "b-card",
                                      { staticClass: "action-card" },
                                      [
                                        _c(
                                          "b-card-body",
                                          {
                                            staticClass:
                                              "action-card-body clearfix",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex justify-content-start align-items-center",
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "fa fa-lightbulb-o",
                                                  staticStyle: {
                                                    "background-color":
                                                      "#225cbd",
                                                    color: "#ffffff",
                                                  },
                                                }),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex flex-column",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "font-weight-bold",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              4,
                                                              "Energy"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c("div", [
                                                      _vm._v("CO"),
                                                      _c("sub", [_vm._v("2")]),
                                                      _vm._v(
                                                        ": " +
                                                          _vm._s(
                                                            _vm
                                                              .formattedCarbonData
                                                              .energy
                                                          )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { cols: "12", md: "6" } },
                                  [
                                    _c(
                                      "b-card",
                                      { staticClass: "action-card" },
                                      [
                                        _c(
                                          "b-card-body",
                                          {
                                            staticClass:
                                              "action-card-body clearfix",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex justify-content-start align-items-center",
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "fa fa-credit-card",
                                                  staticStyle: {
                                                    "background-color":
                                                      "#658fd6",
                                                    color: "#ffffff",
                                                  },
                                                }),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex flex-column",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "font-weight-bold",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              6,
                                                              "Expenses"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c("div", [
                                                      _vm._v("CO"),
                                                      _c("sub", [_vm._v("2")]),
                                                      _vm._v(
                                                        ": " +
                                                          _vm._s(
                                                            _vm
                                                              .formattedCarbonData
                                                              .expenses
                                                          )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm.level !== "user"
                                  ? _c(
                                      "b-col",
                                      { attrs: { cols: "12", md: "6" } },
                                      [
                                        _c(
                                          "b-card",
                                          { staticClass: "action-card" },
                                          [
                                            _c(
                                              "b-card-body",
                                              {
                                                staticClass:
                                                  "action-card-body clearfix",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex justify-content-start align-items-center",
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fa fa-building-o",
                                                      staticStyle: {
                                                        "background-color":
                                                          "#ea4d2c",
                                                        color: "#ffffff",
                                                      },
                                                    }),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex flex-column",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "font-weight-bold",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                this.FormMSG(
                                                                  5,
                                                                  "Locations"
                                                                )
                                                              ) +
                                                                " " +
                                                                _vm._s(
                                                                  this.level
                                                                )
                                                            ),
                                                          ]
                                                        ),
                                                        _c("div", [
                                                          _vm._v("CO"),
                                                          _c("sub", [
                                                            _vm._v("2"),
                                                          ]),
                                                          _vm._v(
                                                            ": " +
                                                              _vm._s(
                                                                _vm
                                                                  .formattedCarbonData
                                                                  .locations
                                                              )
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.level !== "user"
                                  ? _c(
                                      "b-col",
                                      { attrs: { cols: "12", md: "6" } },
                                      [
                                        _c(
                                          "b-card",
                                          { staticClass: "action-card" },
                                          [
                                            _c(
                                              "b-card-body",
                                              {
                                                staticClass:
                                                  "action-card-body clearfix",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex justify-content-start align-items-center",
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fa fa-trash-o",
                                                      staticStyle: {
                                                        "background-color":
                                                          "#9b1b00",
                                                        color: "#ffffff",
                                                      },
                                                    }),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex flex-column",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "font-weight-bold",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                this.FormMSG(
                                                                  7,
                                                                  "Waste"
                                                                )
                                                              ) +
                                                                " " +
                                                                _vm._s(
                                                                  this.level
                                                                )
                                                            ),
                                                          ]
                                                        ),
                                                        _c("div", [
                                                          _vm._v("CO"),
                                                          _c("sub", [
                                                            _vm._v("2"),
                                                          ]),
                                                          _vm._v(
                                                            ": " +
                                                              _vm._s(
                                                                _vm
                                                                  .formattedCarbonData
                                                                  .waste
                                                              )
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  {
                                    attrs: {
                                      cols: _vm.$screen.width > 990 ? 6 : 12,
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { attrs: { id: "chart" } },
                                      [
                                        _c("VueApexCharts", {
                                          attrs: {
                                            width: "410",
                                            type: "donut",
                                            options: _vm.chartOptions,
                                            series: _vm.series,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _vm.carbonModel === 2
                                  ? _c(
                                      "b-col",
                                      {
                                        style:
                                          _vm.$screen.width > 990
                                            ? ""
                                            : "margin-top: 100px",
                                        attrs: {
                                          cols:
                                            _vm.$screen.width > 990 ? 6 : 12,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              position: "absolute",
                                              bottom: "10px",
                                              right: "30px",
                                            },
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: require("./../../assets/img/logo_doconomy.svg"),
                                                alt: "Logo doconomy",
                                              },
                                            }),
                                            _c(
                                              "p",
                                              {
                                                staticStyle: {
                                                  "font-weight": "bolder",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        157,
                                                        "Provided by Doconomy"
                                                      )
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.formattedCarbonData
                  ? _c(
                      "b-col",
                      { attrs: { lg: "4", md: "6" } },
                      [
                        _c(
                          "b-card",
                          { staticClass: "card-border-blue-light" },
                          [
                            _c(
                              "div",
                              { staticClass: "thegreenshot-badge-home" },
                              [
                                _c("div", { staticClass: "paper-badge" }, [
                                  _c("div", { staticClass: "resume" }, [
                                    _c(
                                      "div",
                                      { staticClass: "icon" },
                                      [
                                        _c(_vm.getLucideIcon("Files"), {
                                          tag: "component",
                                          attrs: { size: 22 },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("div", { staticClass: "label" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.formattedCarbonData.tGSEcoPaper
                                        ) +
                                          " " +
                                          _vm._s(_vm.FormMSG(16, "pages"))
                                      ),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "title" }, [
                                    _vm._v(_vm._s(_vm.FormMSG(17, "Paper"))),
                                  ]),
                                ]),
                                _c("div", { staticClass: "separator" }),
                                _c("div", { staticClass: "resume-co2" }, [
                                  _c("div", { staticClass: "resume" }, [
                                    _c(
                                      "div",
                                      { staticClass: "icon" },
                                      [
                                        _c(_vm.getLucideIcon("Sprout"), {
                                          tag: "component",
                                          attrs: { size: 22 },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("div", { staticClass: "label" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.formattedCarbonData.tGSEconomy
                                        )
                                      ),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "title" }, [
                                    _vm._v("CO"),
                                    _c("sub", [_vm._v("2")]),
                                  ]),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _vm._v("CO"),
      _c("sub", [_vm._v("2")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }