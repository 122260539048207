var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "hidden-sm", attrs: { fluid: "" } },
    [
      _vm.displayHeader
        ? _c("evolution-dashboard-header", {
            attrs: {
              "num-ts-to-validate": _vm.numTsToValidate,
              "num-exp-to-validate": _vm.numExpToValidate,
              "num-po-to-validate": _vm.numPoToValidate,
            },
          })
        : _vm._e(),
      _c("h2", { staticClass: "mt-2" }, [_vm._v(_vm._s(this.caption1))]),
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c(
            "b-card-header",
            [
              _c(
                "b-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticClass: "buttons-header" },
                        [
                          _c(
                            "b-button",
                            {
                              class:
                                "Timesheets" === _vm.state.selectedView
                                  ? "btn-current"
                                  : "",
                              attrs: { variant: "light" },
                              on: {
                                click: function ($event) {
                                  return _vm.selectView("Timesheets")
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t" +
                                  _vm._s(this.FormMSG(20, "Timesheets")) +
                                  "\n\t\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                          _c(
                            "b-button",
                            {
                              class:
                                "Expenses" === _vm.state.selectedView
                                  ? "btn-current"
                                  : "",
                              attrs: { variant: "light" },
                              on: {
                                click: function ($event) {
                                  return _vm.selectView("Expenses")
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t" +
                                  _vm._s(this.FormMSG(21, "Expenses")) +
                                  "\n\t\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                          !_vm.isFilmSingle
                            ? _c(
                                "b-button",
                                {
                                  class:
                                    "Purchase orders" === _vm.state.selectedView
                                      ? "btn-current"
                                      : "",
                                  attrs: { variant: "light" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.selectView("Purchase orders")
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        this.FormMSG(22, "Purchase orders")
                                      ) +
                                      "\n\t\t\t\t\t\t\t"
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              staticClass:
                                "dashboard-timeframe-selector-container",
                            },
                            [
                              _c("date-range-picker", {
                                ref: "picker",
                                attrs: {
                                  opens: "left",
                                  "show-week-numbers": true,
                                  "show-dropdowns": true,
                                  "auto-apply": true,
                                  "time-picker": false,
                                  ranges:
                                    _vm.getLanguage() == 1
                                      ? _vm.state.quickDateRangesFr
                                      : _vm.state.quickDateRangesEn,
                                  "linked-calendars": false,
                                  "locale-data":
                                    _vm.getLanguage() == 1
                                      ? {
                                          firstDay: 1,
                                          format: "YYYY-MM-DD",
                                          daysOfWeek: [
                                            "Dim",
                                            "Lun",
                                            "Mar",
                                            "Mer",
                                            "Jeu",
                                            "Ven",
                                            "Sam",
                                          ],
                                          monthNames: [
                                            "Jan",
                                            "Fev",
                                            "Mar",
                                            "Avr",
                                            "Mai",
                                            "Juin",
                                            "Juil",
                                            "Aou",
                                            "Sep",
                                            "Oct",
                                            "Nov",
                                            "Dec",
                                          ],
                                          weekLabel: "S",
                                        }
                                      : {
                                          firstDay: 1,
                                          format: "YYYY-MM-DD",
                                          daysOfWeek: [
                                            "Sun",
                                            "Mon",
                                            "Tue",
                                            "Wed",
                                            "Thu",
                                            "Fri",
                                            "Sat",
                                          ],
                                          monthNames: [
                                            "Jan",
                                            "Feb",
                                            "Mar",
                                            "Apr",
                                            "May",
                                            "Jun",
                                            "Jul",
                                            "Aug",
                                            "Sep",
                                            "Oct",
                                            "Nov",
                                            "Dec",
                                          ],
                                        },
                                },
                                on: { update: _vm.updateValues },
                                scopedSlots: _vm._u([
                                  {
                                    key: "input",
                                    fn: function (picker) {
                                      return [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(_vm.displayedDate) +
                                            "\n\t\t\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                                model: {
                                  value: _vm.state.dateRange,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.state, "dateRange", $$v)
                                  },
                                  expression: "state.dateRange",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-card-body",
            [
              _c("evolution-chart", {
                attrs: {
                  "selected-departments": _vm.state.selectedDepartments,
                  "graph-data": _vm.adminDashboard,
                  "date-range": _vm.state.dateRange,
                  "selected-view": _vm.state.selectedView,
                  "disable-deep-diving":
                    _vm.level === "user" || !!_vm.state.deepDivedDepartment,
                  "deep-dived-department": _vm.state.deepDivedDepartment,
                  height: 480,
                },
                on: { setDeepDivedDepartment: _vm.setDeepDivedDepartment },
              }),
              _vm.level !== "user"
                ? _c("evolution-dashboard-department-selector", {
                    attrs: {
                      departments: _vm.departments,
                      "selected-departments": _vm.state.selectedDepartments,
                    },
                    on: {
                      changeSelectedDepartments:
                        _vm.onSelectDepartmentsFromSelector,
                    },
                  })
                : _vm._e(),
              _c("div"),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }