<template>
	<b-container fluid>
		<b-row>
			<b-col cols="12" md="4">
				<b-button block variant="primary" v-if="numTsToValidate > 0" v-on:click="navigateToPath('/validateTS')">
					{{ numTsToValidate }}
					<span>{{ this.FormMSG(10, 'timesheets to validate') }}</span>
				</b-button>
			</b-col>
			<b-col cols="12" md="4">
				<b-button block variant="primary" v-if="numExpToValidate > 0" v-on:click="navigateToPath('/validateExp')">
					{{ numExpToValidate }}
					<span>{{ this.FormMSG(11, 'expenses to validate') }}</span>
				</b-button>
			</b-col>

			<b-col cols="12" md="4">
				<b-button block variant="primary" v-if="numPoToValidate > 0" v-on:click="navigateToPath('/validatePO')">
					{{ numPoToValidate }}
					<span>{{ this.FormMSG(12, 'purchase orders to validate') }}</span>
				</b-button>
			</b-col>
		</b-row>
	</b-container>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';

export default {
	name: 'evolutionDashboardHeader',
	mixins: [languageMessages],
	components: {},
	props: {
		numTsToValidate: Number,
		numExpToValidate: Number,
		numPoToValidate: Number
	},
	methods: {
		navigateToPath(path) {
			this.$router.push(path);
		}
	}
};
</script>

<style scoped>
.header-container {
	padding-bottom: 24px;
	color: white;
}
.col-center {
	display: flex;
	justify-content: center;
}

.action-card {
	cursor: pointer;
	width: 240px;
	height: 100px;
	text-align: center;
	padding: 0 !important;
	background-color: rgba(52, 170, 58, 0.7);
}

.action-card-body {
	height: 80px;
	padding: 0 !important;
}

.card-title {
	text-align: center;
}
</style>
